<template>
  <div>
    <div class="type-box">
      <div class="_Select">
        <span>年份：</span>
        <el-date-picker
          v-model="year"
          format="yyyy"
          value-format="yyyy"
          type="year"
          placeholder="选择年"
        >
        </el-date-picker>
      </div>
      <el-button
        type="success"
        icon="el-icon-search"
        class="find"
        @click="handleQuery"
        >搜索</el-button
      >
      <el-button
        v-show="isShow"
        icon="el-icon-back"
        @click="handleQuery"
        >返回</el-button
      >
    </div>
    <el-table
      :data="tableData.slice(start, end)"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      @row-dblclick="handleRowClick"
      style="width: 98%"
      id="table"
      :height="elementHeight"
      border
    >
      <el-table-column type="index" width="100" label="序号"> </el-table-column>
      <el-table-column prop="compName" label="公司"> </el-table-column>
      <el-table-column prop="deptName" label="部门">
      </el-table-column>
      <el-table-column prop="postName" label="岗位"> </el-table-column>
      <el-table-column prop="peopleCount" label="人数"> </el-table-column>
      <el-table-column prop="avgScore" label="平均分"> </el-table-column>
    </el-table>
    
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20, 50]"
      :page-size="curSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
      class="_Pagination"
    >
    </el-pagination>
  </div>
</template>

<script>
import { get } from '../../api/http'
export default {
  data() {
    return {
      elementHeight: 0,
      tableData: [],
      start: 0,
      end: 10,
      curSize: 10,
      currentPage: 1,
      year: new Date().getFullYear() + '',
      isShow: false,
    }
  },
  mounted() {
    this.getElementHeight()
    this.getList()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 120)
      })
    },
    getList(){
      get('/api/AuditTestReport?Years=' + this.year).then(res =>{
        this.tableData = res.data;
        this.isShow = false;
      })
    },
    handleQuery(){
      this.getList();
      this.start = 0;
      this.end = 10;
      this.curSize = 10;
      this.currentPage = 1;
    },
    // 双击表格
    handleRowClick(row){
      if(row.compId != 0 && this.isShow == false){
        let data = {
          Years: this.year,
          CompId:row.compId
        }
        get('/api/AuditTestReport',data).then(res =>{
          this.tableData = res.data;
          this.isShow = true;
          this.start = 0;
          this.end = 10;
        })
      }
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.currentPage = e;
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
  },
}
</script>

<style lang="less" scoped >
.type-box {
  position: relative;
  display: flex;
  padding-bottom: 10px;
  align-items: center;
  .find{
    margin-left: 10px;
  }
}
</style>